/**
 ** 環境設定値管理クラス
 **
 ** アプリケーション環境変数を管理します。
 ** 環境変数へのアクセスは、ヘルパー関数`config`へドットアクセス記法を使って行います。
 **
 ** 例）
 ** ・取得
 **  config('ua.event.CLICK');
 ** ・設定
 **  config( 'ua.event.CLICK', 'touchend' );
 **
 ** @version  1.0.2
 ** @author   no.6@ooooh.jp
 **/

import { UserAgent } from './ua.js';

export class Config
{
  constructor()
  {
    this.init();
  }

  init()
  {
    this.repository = {
      runMode: /^localhost\./.test(location.host)
               ? 'development'
               : 'production',
      location: {
        hash:     location.hash,
        host:     location.host,
        href:     location.href,
        origin:   location.origin,
        pathname: location.pathname,
        port:     location.port,
        protocol: location.protocol
      },
      referrer: document.referrer,
      ua: new UserAgent()
    };
  }

  get( property )
  {
    const _ = function( repository, property )
    {
      if(!property){
        return repository;
      }

      let properties = property.split('.');

      return _( repository[ properties.shift()], properties.join('.'));
    };

    return _( this.repository, property );
  }

  set( property, value )
  {
    const _ = ( repository, property ) => {
      if(!property){
        return repository;
      }

      let properties = property.split('.');
      let key        = properties.shift();
      let candidate  = repository[key];

      if( candidate !== undefined ){
        return _( candidate, properties.join('.'));
      }else{
        repository[key] = {};

        return _( repository[key], properties.join('.'))
      }
    };

    let properties = property.split('.');
    let key =        properties.pop();

    let repository = _( this.repository, properties.join('.'));

    repository[key] = value;
  }

  dump()
  {
    console.log(this.repository);
  }
}