import { Partial } from '../foundation/partial';

import { Swiper } from 'swiper/bundle';

export class Header extends Partial
{
  constructor( element )
  {
    super(element);
  }

  boot()
  {
    super.boot();

    this.component.swiper = new Swiper( '.app--header .swiper--container', {
      autoplay: {
        delay: 6400,
      },
      loop: true,
      pagination: { 
        clickable: true,
        el: '.swiper--pagination',
        type: 'bullets',
        bulletActiveClass: 'swiper--pagination-bullet-active',
        bulletClass: 'swiper--pagination-bullet',
        clickableClass: 'swiper--pagination-clickable',
        currentClass: 'swiper--pagination-current',
        hiddenClass: 'swiper--pagination-hidden',
        horizontalClass: 'swiper--pagination-horizontal',
        lockClass: 'swiper--pagination-lock',
        modifierClass: 'swiper--pagination-',
        progressbarFillClass: 'swiper--pagination-progressbar-fill',
        progressbarOppositeClass: 'swiper--pagination-progressbar-opposite',
        totalClass: 'swiper--pagination-total',
        verticalClass: 'swiper--pagination-vertical',
      },
      slidesPerView: 1,
      speed: 800,
      containerModifierClass: 'swiper--',
      noSwipingClass: 'swiper--no-swiping',
      slideActiveClass: 'swiper--slide-active',
      slideBlankClass: 'swiper--slide-invisible-blank',
      slideClass: 'swiper--slide',
      slideDuplicateActiveClass: 'swiper--slide-duplicate-active',
      slideDuplicateClass: 'swiper--slide-duplicate',
      slideDuplicateNextClass: 'swiper--slide-duplicate-next',
      slideDuplicatePrevClass: 'swiper--slide-duplicate-prev',
      slideNextClass: 'swiper--slide-next',
      slidePrevClass: 'swiper--slide-prev',
      slideVisibleClass: 'swiper--slide-visible',
      wrapperClass: 'swiper--wrapper',
      on: {
        init: function()
        {
          this.slides.forEach(( item ) => {
            let img = item.querySelector('img');

            img.style.opacity = 0;
            item.style.backgroundImage = 'url('+ img.src +')';
          });
        }
      }
    });
  }

  booted()
  {
    super.booted();
  }
}