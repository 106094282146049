export class Intersect
{
  static observe( elements, callback, options )
  {
    if(  ( options === undefined )
      || ( options === null )
    ){
      options = {
        // root:       config('intersect.ROOT'),
        rootMargin: '0px', // config('intersect.ROOT_MARGIN'),
        threshold:  0 // config('intersect.THRESHOLD')
      };
    }

    const observer = new IntersectionObserver( check, options );

    elements.forEach(( element ) => {
      observer.observe(element);
    });

    function check( elements )
    {
      if(callback){
        elements.forEach(( element ) => {
          callback( element.target, element.isIntersecting, observer );
        });
      }
    }
  }
}