import { Partial } from '../foundation/partial';

export class Footer extends Partial
{
  constructor( element )
  {
    super(element);
  }

  boot()
  {
    super.boot();
  }

  booted()
  {
    super.booted();
  }
}