export class Partial
{
  constructor( element )
  {
    this.element = element;

    this.component = {};
  }

  boot()
  {

  }

  booted()
  {

  }
}