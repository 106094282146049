/**
 ** UserAgent 判別クラス
 **
 ** @todo
 ** ・Supportクラスは未完成
 **
 ** @version  2.0
 ** @author   no.6@ooooh.jp
 **/

class Support
{
  constructor( user_agent, vendor, version )
  {
    this.TRANSFORM = (() => {
      const props = {
              WebkitTransform: 'webkitTransform',
              MoxTransform:    'mozTransform',
              OTransform:      'oTransform otransform',
              transform:       'transform'
            };

      for( let name in props ){
        if(( document.body || document.documentElement ).style[name] != undefined ){
          return props[name];
        }
      }
    })();

    this.FILE = (() => {
      return ( window.FILE && window.FileReader ) || false;
    })();

    this.FORM_DATA = (() => {
      return window.FormData || false;
    })();

    this.HISTORY = (() => {
      return ( window.history && window.history.pushState ) || false;
    })();

    this.STORAGE = (() => {
      return window.localStorage || false;
    })();
  }
}

class Event
{
  constructor( user_agent, vendor, version )
  {
    const touch =  (( 'ontouchstart' in window ) && /mobile|tablet/.test( user_agent.toLowerCase()))
                || ( window.DocumentTouch && ( document instanceof DocumentTouch ))
                || ( navigator.msPointerEnabled && ( navigator.msMaxTouchPoints > 0 ))
                || ( navigator.pointerEnabled && ( navigator.maxTouchPoints > 0 ))
                || false;

    this.animation = (() => {
      return {
        START: (() => {
          const events = {
                  WebkitAnimation: 'webkitAnimation',
                  MozAnimation:    'mozAnimation',
                  OAnimation:      'oAnimation oanimation',
                  animation:       'animation'
                };

          for( let name in events ){
            if(( document.body || document.documentElement ).style[name] != undefined ){
              return events[name];
            }
          }
        })(),
        END: (() => {
          const events = {
                  WebkitAnimation: 'webkitAnimationEnd',
                  MozAnimation:    'animationend',
                  OAnimation:      'oAnimationEnd oanimationend',
                  animation:       'animationend'
                };

          for( let name in events ){
            if(( document.body || document.documentElement ).style[name] != undefined ){
              return events[name];
            }
          }
        })()
      };
    })();

    this.transition = (() => {
      return {
        START: (() => {
          const events = {
            WebkitTransition: 'webkitTransition',
            MozTransition:    'transition',
            OTransition:      'oTransition otransition',
            transition:       'transition'
          };

          for( let name in events ){
            if(( document.body || document.documentElement ).style[name] != undefined ){
              return events[name];
            }
          }
        })(),
        END: (() => {
          const events = {
            WebkitTransition: 'webkitTransitionEnd',
            MozTransition:    'transitionend',
            OTransition:      'oTransitionEnd otransitionend',
            transition:       'transitionend'
          };

          for( let name in events ){
            if(( document.body || document.documentElement ).style[name] != undefined ){
              return events[name];
            }
          }
        })()
      };
    })();

    Object.assign( this, {
      // Frame | Object Events
      dom: {
        LOADED: 'DOMContentLoaded'
      },
      hash: {
        CHANGED: 'hashchange'
      },
      network: {
        OFFLINE: 'offline',
        ONLINE:  'online',
      },
      page: {
        HIDE: 'pagehide',
        SHOW: 'pageshow',
      },
      stoprage: {
        UPDATED: 'storage',
      },

      LOADED:    'load',
      MESSAGE:   'message',
      POPSTATE:  'popstate',
      RESIZE:    'resize',
      SCROLL:    'scroll',
      SHOW:      'show',
      UNLOADING: 'beforeunload',
      UNLOADED:  'unload',

      // Mouse | TouchPad Events
      pointer: {
        CLICK:  touch ? 'touchend' : 'click',
        //DOUBLE_CLICK: this.TOUCH ? 'touchend' : 'dbclick',
        DOWN:   touch ? 'touchstart' : 'mousedown',
        ENTER:  touch ? 'touchenter' : 'mouseenter',
        LEAVE:  touch ? 'touchleave' : 'mouseleave',
        MOVE:   touch ? 'touchmove' : 'mousemove',
        OUT:    'mouseout',
        OVER:   'mouseover',
        UP:     touch ? 'touchend' : 'mouseup',
        WHEEL:  'mousewheel',
        CANCEL: 'touchcancel',
      },

      // Keyboard Events
      key: {
        DOWN:  'keydown',
        PRESS: 'keypress',
        UP:    'keyup',
      },

      // Form Events
      form: {
        BLUR:      'blur',
        CHANGE:    'change',
        FOCUS:     'focus',
        DATA:      'formdata',
        INPUT:     'input',
        INVALID:   'invalid',
        RESET:     'reset',
        SELECT:    'select',
        SELECTING: 'selectstart',
        SUBMIT:    'submit',
      },

      // Several Events
      ABORT:  'abort',
      CANCEL: 'cancel',
      ERROR:  'error',

      // Other Events
      orientation: {
        ROTATED: 'orientationchange'
      },
      print: {
        BEFORE: 'beforeprint',
        AFTER:  'afterprint',
      },
      xhr: {
        state: {
          CHANGE: 'readystatechange'
        }
      },

      // Custom Events
      BOOTED:  'booted',
      BOOTING: 'booting',
      INITED:  'inited',
      INITING: 'initing',
      MOUNTED: 'mounted',
    });
  }
}

export class UserAgent
{
  constructor()
  {
    this.userAgent =  ( 'navigator' in window )
                   && ( 'userAgent' in navigator )
                   && ( navigator.userAgent.toLowerCase() || '' );

    this.vendor = ( 'navigator' in window )
               && ( 'vendor' in navigator )
               && ( navigator.vendor.toLowerCase() || '' );

    this.version = ( 'navigator' in window )
                && ( 'appVersion' in navigator )
                && ( navigator.appVersion.toLowerCase() || '' );

    this.support = new Support( this.userAgent, this.vendor, this.version );
    this.event   = new Event( this.userAgent, this.vendor, this.version );

    // Browser
    this.browser = {
      CHROME: (() => {
        return /chrome|chromium/i.test(this.userAgent)
            && /google inc/.test(this.vendor);
      })(),
      FIREFOX: (() => {
        return /firefox/i.test(this.userAgent);
      })(),
      IE: (() => {
        return /msie/i.test(this.userAgent)
            || ( 'ActiveXObject' in window );
      })(),
      EDGE: (() => {
        return /edge/i.test(this.userAgent);
      })(),
      OPERA: (() => {
        return /^Opera\//.test(this.userAgent)
            || /\x20OPR\//.test(this.userAgent);
      })(),
      SAFARI: (() => {
        // Chrome Devtoolだとエラーが出る
        return /safari/i.test(this.userAgent)
            && /apple computer/i.test(this.vendor);
      })()
    };

    // Device
    this.device = {
      IPHONE: (() => {
        return /iphone/i.test(this.userAgent);
      })(),
      IPAD: (() => {
        return /ipad/i.test(this.userAgent);
      })(),
      IPOD: (() => {
        return /ipod/i.test(this.userAgent);
      })()
    };

    // OS
    this.os = {
      MAC: (() => {
        return /mac/i.test(this.version)
            && !this.device.IPHONE
            && !this.device.IPAD
            && !this.device.IPOD;
      })(),
      IOS: (() => {
        return this.device.IPHONE || this.device.IPAD || this.device.IPOD;
      })(),
      WINDOWS: (() => {
        return /win/i.test(this.version);
      })(),
      ANDROID: (() => {
        return /android/i.test(this.userAgent);
      })(),
      LINUX: (() => {
        return /linux/i.test(this.version);
      })(),
    };

    // Device
    Object.assign( this.device, {
      ANDROID_PHONE: (() => {
        return this.os.ANDROID && /mobile/i.test(this.userAgent);
      })(),
      ANDROID_TABLET: (() => {
        return this.os.ANDROID && !/mobile/i.test(this.userAgent);
      })(),
      WINDOWS_PHONE: (() => {
        return this.os.WINDOWS && /phone/i.test(this.userAgent);
      })(),
      FIREFOX_PHONE: (() => {
        return this.browser.FIREFOX && /mobile/i.test(this.userAgent);
      })(),
      FIREFOX_TABLET: (() => {
        return this.browser.FIREFOX && /tablet/i.test(this.userAgent);
      })(),
    });

    Object.assign( this.device, {
      WINDOWS_TABLET: (() => {
        return  this.os.WINDOWS
            && !this.device.WINDOWS_PHONE
            && /touch/i.test(this.userAgent);
      })(),
    });

    // Platform
    this.platform = {
      MOBILE: (() => {
        return this.device.IPHONE
            || this.device.IPOD
            || this.device.ANDROID_PHONE
            || this.device.WINDOWS_PHONE
            || this.device.FIREFOX_PHONE;
      })(),
      TABLET: (() => {
        return this.device.IPAD
            || this.device.ANDROID_TABLET
            || this.device.WINDOWS_TABLET
            || this.device.FIREFOX_TABLET;
      })(),
    };

    Object.assign( this.platform, {
      DESKTOP: (() => {
        return !this.platform.MOBILE
            && !this.platform.TABLET;
      })(),
    });
  }
}