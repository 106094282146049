import { FAB } from '../component/fab';
import { Header } from '../partial/header';
import { Shop } from '../partial/shop';
import { Footer } from '../partial/footer';

export class App
{
  constructor()
  {
    this.element = document.getElementById('chariano--app');
  }

  /**
   ** アプリケーションの立ち上げ前に呼ばれます。
   **
   ** @return void
   **/
  boot( callback )
  {
    this.partial = {
      header: new Header( this.element.querySelector('.app--header')),
      shop: new Shop( this.element.querySelector('.shop')),
      footer: new Footer( this.element.querySelector('.app--footer'))
    };

    this.component = {
      fab: new FAB( this.element.querySelector('.fab'))
    };

    Object.keys(this.partial).forEach(( key ) => {
      if(  ( this.partial[key].boot !== undefined )
        && ( typeof this.partial[key].boot === 'function' )
      ){
        this.partial[key].boot();
      }
    });

    Object.keys(this.component).forEach(( key ) => {
      if(  ( this.component[key].boot !== undefined )
        && ( typeof this.component[key].boot === 'function' )
      ){
        this.component[key].boot();
      }
    });

    if(callback){
      callback();
    }
  }

  /**
   ** アプリケーションの立ち上げ完了後に呼ばれ、アプリケーションマウント完了イベントを
   ** 発火。
   **
   ** @return void
   **/
  booted()
  {
    this.element.setAttribute( 'data-appid', '.0' );

    this.addAppId( this.element, this.element.dataset.appid );
    this.addClassList();

    // マウント完了イベント発火
    setTimeout(() => {
      trigger( window, config('ua.event.MOUNTED'));

      Object.keys(this.partial).forEach(( key ) => {
        if(  ( this.partial[key].booted !== undefined )
          && ( typeof this.partial[key].booted === 'function' )
        ){
          this.partial[key].booted();
        }
      });
    }, 10 );
  }

  /**
   ** アプリケーションのコンテナ要素以下のブロックレベルのDOM要素に識別IDを設定。
   **
   ** @return void
   **/
  addAppId( parent, id )
  {
    // 除外するタグ
    const tags = [
      'b', 'big', 'br',
      'em',
      'font',
      'g',
      'i',
      'mark',
      'path', 'polygon',
      'rect',
      's', 'script', 'small', 'span', 'strike', 'strong',
      'u',
    ];

    const _ = ( _element, _id, _idx ) => {
      _element.setAttribute( 'data-appid', _id + '.'+ _idx );

      this.addAppId( _element, _id +'.'+ _idx );
    };

    if( parent.hasChildNodes()){
      // Elementノードのみ
      parent.children.toArray().forEach(( element, idx ) => {
        if( config('ua.os.WINDOWS') && config('ua.browser.IE')){

        }else{
          if( !tags.includes( element.tagName.toLowerCase())){
            _( element, id, idx );
          }else{
            let style = window.getComputedStyle(element);

            if( style.getPropertyValue('display') == 'block' ){
              _( element, id, idx );
            }

            // @todo 親要素の`style.display`の値が`flex`の場合
          }
        }
      });
    }
  }

  /**
   ** アプリケーションのコンテナ要素に実行環境情報をクラス名として設定。
   **
   ** @return void
   **/
  addClassList()
  {
    const class_list = [
      ( config('ua.os.IOS') ? 'ios'
        : config('ua.os.MAC') ? 'mac'
        : config('ua.os.WINDOWS') ? 'windows'
        : config('ua.os.LINUX') ? 'linux'
        : 'os--unknown'
      ),
      ( config('ua.platform.MOBILE') ? 'mobile'
        : config('ua.platform.TABLET') ? 'tablet'
        : config('ua.platform.DESKTOP') ? 'desktop'
        : 'platform--unknown'
      ),
      ( config('ua.browser.IE') ? 'ie'
        : config('ua.browser.EDGE') ? 'edge'
        : config('ua.browser.CHROME') ? 'chrome'
        : config('ua.browser.FIREFOX') ? 'firefox'
        : config('ua.browser.SAFARI') ? 'safari'
        : config('ua.browser.OPERA') ? 'opera'
        : 'browser--unknown'
      ),
      'is--mounted'
    ];



    this.element.classList.add(...class_list);
  }
}