import { Config } from './config';

const _config = new Config();

/**
 ** 環境設定値へアクセスする為のヘルパー関数
 **
 ** @param  string  property
 ** @param  mixed   value
 **
 ** @return mixed
 **/
window.config = ( property, value ) => {
  if(!value){
    return _config.get(property);
  }

  _config.set( property, value );
};

/**
 ** 環境設定をコンソールへ出力するためのヘルパー関数
 **/
window.dump = () => {
  _config.dump();
};

/**
 ** 空文字を`null`に変換するためのヘルパー関数
 **
 ** @param  string  text
 **
 ** @return mixed
 **/
if(!window.emptyToNull){
  window.emptyToNull = ( text ) => {
    if( text == '' ){
      return null;
    }

    return text;
  };
}

/**
 ** DOMを生成する際にID属性を付加するためのヘルパー関数
 **
 ** @param  string  tag
 ** @param  string  id
 **
 ** @return HTMLElement
 **/
if(!document.createElementWithId){
  document.createElementWithId = ( tag, id ) => {
    let element = document.createElement(tag);

    element.id = id;

    return element;
  }
}

/**
 ** DOMを生成する際に単体またh複数のCLASS属性を付加するためのヘルパー関数
 **
 ** @param  string  tag
 ** @param  mixed   classname
 **
 ** @return HTMLElement
 **/
if(!document.createElementWithClassName){
  document.createElementWithClassName = ( tag, classname ) => {
    let element = document.createElement(tag);

    if( typeof classname == 'string' ){
      element.classList.add(classname);
    }else{
      classname.forEach(( name, idx ) => {
        element.classList.add(name);
      });
    }

    return element;
  }
}