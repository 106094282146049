import { Partial } from '../foundation/partial';

import { Swiper } from 'swiper/bundle';
import { Base } from '../component/base';

export class Shop extends Partial
{
  constructor( element )
  {
    super(element);
  }

  boot()
  {
    super.boot();

    if( window.innerWidth < 640 ){
      this.initSwiper();
    }

    this.component.base = new Base();
    this.component.base.load( 'https://chariano.jp/api', ( items ) => {
      this.items = items;
    });
  }

  booted()
  {
    super.booted();

    window.addEventListener( config('ua.event.RESIZE'), ( event ) => {
      if( window.innerWidth >= 640 ){
        if(  ( this.component.swiperThumbnail != undefined )
          && ( this.component.swiper != undefined )
        ){
          this.component.swiperThumbnail.destroy();
          this.component.swiper.destroy();

//          this.initSwiper();
        }else{

        }
      }else{
        if(  ( this.component.swiperThumbnail != undefined )
          && ( this.component.swiper != undefined )
        ){
          // if(  ( this.component.swiperThumbnail.destroyed != undefined )
          //   && ( this.component.swiper.destroyed != undefined )
          // ){
          //   this.component.swiperThumbnail = null;
          //   this.component.swiper = null;
          // }

          // this.initSwiper();
        }else{
          this.initSwiper();
        }
      }
    });
  }

  initSwiper()
  {
    this.component.swiperThumbnail = new Swiper( '.shop .swiper--thumbnail.swiper--container', {
      slidesPerView: 6,
      freeMode: true,
      watchSlidesVisibilitiy: true,
      watchSlidesProgress: true,
      slideThumbActiveClass: '.swiper--slide-thumb-active',
      thumbsContainerClass: 'swiper--thumbnail',
      containerModifierClass: 'swiper--',
      noSwipingClass: 'swiper--no-swiping',
      slideActiveClass: 'swiper--slide-active',
      slideBlankClass: 'swiper--slide-invisible-blank',
      slideClass: 'swiper--slide',
      slideDuplicateActiveClass: 'swiper--slide-duplicate-active',
      slideDuplicateClass: 'swiper--slide-duplicate',
      slideDuplicateNextClass: 'swiper--slide-duplicate-next',
      slideDuplicatePrevClass: 'swiper--slide-duplicate-prev',
      slideNextClass: 'swiper--slide-next',
      slidePrevClass: 'swiper--slide-prev',
      slideVisibleClass: 'swiper--slide-visible',
      wrapperClass: 'swiper--wrapper',
    });

    this.component.swiper = new Swiper( '.shop .swiper--container:not(.swiper--thumbnail)', {
      autoplay: {
        delay: 6400,
      },
      loop: true,
      navigation: {
        nextEl: '.swiper--button-next',
        prevEl: '.swiper--button-prev',
      },
      pagination: { 
        clickable: true,
        el: '.swiper--pagination',
        type: 'bullets',
        bulletActiveClass: 'swiper--pagination-bullet-active',
        bulletClass: 'swiper--pagination-bullet',
        clickableClass: 'swiper--pagination-clickable',
        currentClass: 'swiper--pagination-current',
        hiddenClass: 'swiper--pagination-hidden',
        horizontalClass: 'swiper--pagination-horizontal',
        lockClass: 'swiper--pagination-lock',
        modifierClass: 'swiper--pagination-',
        progressbarFillClass: 'swiper--pagination-progressbar-fill',
        progressbarOppositeClass: 'swiper--pagination-progressbar-opposite',
        totalClass: 'swiper--pagination-total',
        verticalClass: 'swiper--pagination-vertical',
      },
      thumbs: {
        swiper: this.component.swiperThumbnail
      },
      slidesPerView: 1,
      speed: 800,
      containerModifierClass: 'swiper--',
      noSwipingClass: 'swiper--no-swiping',
      slideActiveClass: 'swiper--slide-active',
      slideBlankClass: 'swiper--slide-invisible-blank',
      slideClass: 'swiper--slide',
      slideDuplicateActiveClass: 'swiper--slide-duplicate-active',
      slideDuplicateClass: 'swiper--slide-duplicate',
      slideDuplicateNextClass: 'swiper--slide-duplicate-next',
      slideDuplicatePrevClass: 'swiper--slide-duplicate-prev',
      slideNextClass: 'swiper--slide-next',
      slidePrevClass: 'swiper--slide-prev',
      slideVisibleClass: 'swiper--slide-visible',
      wrapperClass: 'swiper--wrapper',
      on: {
        init: function()
        {
          this.slides.forEach(( item ) => {
            let img = item.querySelector('img');

            img.style.opacity = 0;
            item.style.backgroundImage = 'url('+ img.src +')';
          });
        },
      }
    });
  }
}