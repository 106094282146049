/**
 ** Element.closet
 **
 ** @see    https://developer.mozilla.org/ja/docs/Web/API/Element/closest#Polyfill
 **/
if(!Element.prototype.matches){
  Element.prototype.matches =  Element.prototype.msMatchesSelector
                            || Element.prototype.webkitMatchesSelector;
}

if(!Element.prototype.closest){
  Element.prototype.closest = function( selector )
  {
    const _ = this;

    do{
      if( _.matches(selector)) return _;

      _ = _.parentElement || _.parentNode;
    }while(( _ !== null ) && ( _.nodeType === 1 ));

    return null;
  };
}