import { Intersect } from './intersect';

export class FAB
{
  constructor( element )
  {
    this.element = element;

    this.init();
  }

  init()
  {
    console.log(this);
    this.action = {
      element: this.element.querySelector('a')
    };
  }

  boot()
  {
    this.action.element.addEventListener( config('ua.event.pointer.CLICK'), ( event ) => {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    });

    window.addEventListener( config('ua.event.SCROLL'), ( event ) => {
      if( this.element.classList.contains('is--active')){
        //if(( window.scrollY + window.innerHeight ) > ( document.body.clientHeight - 320 )){
        //  this.element.style.bottom = 8 + ( 320 - ( document.body.clientHeight - ( window.scrollY + window.innerHeight))) +'px';
        //}else{
          this.element.style.bottom = '8px';
        //}
      }
    });

    Intersect.observe(
      [ app.element.querySelector('.app--footer') ],
      ( element, flag, observer ) => {
        if(flag){
          this.element.classList.add( 'is--intersected', 'is--active' );

          setTimeout(() => {
            this.element.classList.remove('is--intersected');
          }, 280 );
        }else{
          this.element.removeAttribute('style');

          this.element.classList.remove('is--active');
          this.element.classList.add('is--intersected');

          setTimeout(() => {
            this.element.classList.remove('is--intersected');
          }, 280 );
        }
      },
      {
        root: null, // app.element.querySelector('.app--footer'),
        rootMargin: '0px',
        threshold: 0
      }
    );
  }
}