var Promise = require('es6-promise').Promise;

export class Base
{
  constructor()
  {
  }
  /**
}
object(stdClass)#1 (1) {
  ["items"]=>
  array(1) {
    [0]=>
    object(stdClass)#2 (17) {
      ["item_id"]=>
      int(58507145)
      ["title"]=>
      string(43) "繝舌せ繧ｯ繝√�繧ｺ繧ｱ繝ｼ繧ｭ縲12cm繝帙�繝ｫ"
      ["detail"]=>
      string(0) ""
      ["price"]=>
      int(2300)
      ["proper_price"]=>
      NULL
      ["item_tax_type"]=>
      int(2)
      ["stock"]=>
      int(2)
      ["visible"]=>
      int(1)
      ["list_order"]=>
      int(1)
      ["identifier"]=>
      NULL
      ["modified"]=>
      int(1643903559)
      ["img1_origin"]=>
      string(86) "https://base-ec2.akamaized.net/images/item/origin/538098ce37a48101fa2db7bc77e06768.jpg"
      ["img2_origin"]=>
      NULL
      ["img3_origin"]=>
      NULL
      ["img4_origin"]=>
      NULL
      ["img5_origin"]=>
      NULL
      ["variations"]=>
      array(0) {
      }
    }
  }
}
   **/
  load( uri, resolve, reject )
  {
    let promise = () => {
      return new Promise(( resolve, reject ) => {
        let request = new XMLHttpRequest();

        request.open( 'GET', uri, true );
        request.responseType = 'json';
        request.setRequestHeader(
          'Content-Type',
          'application/x-www-form-urlencoded'
        );
        request.withCredentials = false;
        request.onload = () => {
          if( request.status === 200 ){
            resolve(request.response.media);
          }else{
            reject( new Error(request.statusText));
          }
        };

        request.send(null);
      });
    };

    promise().then(( data ) => {
      this.clean( data, resolve );
    }).catch(( error ) => {
      rejected(error);
    });
  }

  clean( data, callback )
  {
    callback(data.items);
  }
}